import { PhotoCaptureAppConfig, Sight, CameraHeight } from '@monkvision/types';
import {
  Button,
  IconAroundVehicle,
  IconVerticalPosition,
  SightOverlay,
} from '@monkvision/common-ui-web';
import { useTranslation } from 'react-i18next';
import { sights } from '@monkvision/sights';
import { getLanguage } from '@monkvision/common';
import { usePhotoCaptureHUDSightTutorialStyles } from './hooks';
import { styles } from './PhotoCaptureHUDSightTutorial.styles';

// function getSightsByLabel(label: string): string[] {
//   return Object.values(sights).reduce<string[]>((acc, sight) => {
//     if (sight.label === label) {
//       acc.push(sight.id);
//     }
//     return acc;
//   }, []);
// }
//
// function getOrientation(sightId: string, angleBySightId: Record<number, string[]>): number {
//   const orientation = Object.entries(angleBySightId).find(([_, values]) =>
//     values.includes(sightId),
//   )?.[0];
//   return orientation ? Number.parseInt(orientation, 10) : 0;
// }
//
/**
 * The props for the PhotoCaptureHUDSightTutorial component.
 */
export interface PhotoCaptureHUDSightTutorialProps
  extends Pick<PhotoCaptureAppConfig, 'sightTutorial'> {
  /**
   * The sight to display the tutorial for.
   */
  selectedSight: Sight;
  /**
   * Callback called when the user clicks on the "close" button in Sight Tutorial.
   */
  onClose?: () => void;
  /**
   * The current tutorial step in PhotoCapture component.
   */
  show?: boolean;
}

/**
 * This component displays the sight tutorial for a specific sight.
 */
export function PhotoCaptureHUDSightTutorial({
  selectedSight,
  sightTutorial,
  onClose = () => {},
  show,
}: PhotoCaptureHUDSightTutorialProps) {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const style = usePhotoCaptureHUDSightTutorialStyles();

  // const angleBySightId: Record<number, string[]> = {
  //   0: getSightsByLabel(sights['jgc21-QIvfeg0X'].label),
  //   45: getSightsByLabel(sights['jgc21-KyUUVU2P'].label),
  //   90: getSightsByLabel(sights['jgc21-z15ZdJL6'].label),
  //   180: getSightsByLabel(sights['jgc21-m2dDoMup'].label),
  //   270: getSightsByLabel(sights['jgc21-3JJvM7_B'].label),
  // };
  //
  const sightTutorialFound = sightTutorial?.find((value) =>
    Object.keys(value.imageReferenceBySightId).includes(selectedSight.id),
  );
  const tutorialGuideline = sightTutorialFound?.[getLanguage(i18n.language)];

  let tutorialImage: string | null = '';
  if (sightTutorialFound) {
    tutorialImage = sightTutorialFound.imageReferenceBySightId[selectedSight.id];
  } else if (selectedSight.tutorial?.referencePicture) {
    tutorialImage = selectedSight.tutorial?.referencePicture;
  } else {
    tutorialImage = null;
  }

  return show ? (
    <div style={styles['container']}>
      <div style={styles['iconsContainer']}>
        <div style={styles['icon']}>
          <IconAroundVehicle
            size={80}
            // orientation={getOrientation(selectedSight.id, angleBySightId)}
            orientationAngle={selectedSight.tutorial?.orientation}
            positionAroundVehicle={selectedSight.tutorial?.position}
            disabledCircle={false}
          />
        </div>
        <div style={styles['icon']}>
          <IconVerticalPosition size={67} position={selectedSight.tutorial?.height} />
        </div>
        <div style={styles['icon']}>
          <SightOverlay style={styles['sightIcon']} sight={selectedSight} />
        </div>
      </div>
      <div style={styles['tutorialContainer']}>
        <div style={styles['imageContainer']}>
          <div style={styles['titleContainer']}>
            {/* <Button style={styles['closeButtonFiller']} icon='close' variant='text'></Button> */}
            <span>{t('Tutorial')}</span>
            <Button style={styles['closeButton']} icon='close' variant='text' onClick={onClose} />
          </div>
          {tutorialImage && (
            <img
              style={styles['image']}
              // src={sightTutorialFound?.imageReferenceBySightId[selectedSight.id]}
              src={tutorialImage}
              alt={selectedSight.id}
            />
          )}
        </div>
        <span style={style.guideline}>{tutorialGuideline}</span>
      </div>
    </div>
  ) : null;
}
