import { Styles } from '@monkvision/types';

export const styles: Styles = {
  container: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  car: { position: 'absolute' },
  pov: {
    position: 'absolute',
    // transform: `rotate(${orientation}deg)`,
    // transform: `rotate(${orientation}deg) translate(15px,15px)`,
  },
};
