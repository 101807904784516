import { useResponsiveStyle } from '@monkvision/common';
import { styles } from './IconAroundVehicle.styles';

export interface IconAroundVehicleParams {
  size: number;
  positionAroundVehicle: number;
  orientationAngle?: number;
}

function getPointOnCircle(angleDegrees: number, radius: number): { x: number; y: number } {
  const shiftedAngleDegrees = angleDegrees - 90;
  const angleRadians = shiftedAngleDegrees * (Math.PI / 180);

  const x = Math.round(radius * Math.cos(angleRadians)) * -1;
  const y = Math.round(radius * Math.sin(angleRadians)) * -1;

  return { x, y };
}

export function useIconAroundVehicleStyle({
  size,
  positionAroundVehicle,
  orientationAngle,
}: IconAroundVehicleParams) {
  const { responsive } = useResponsiveStyle();
  const pos = getPointOnCircle(positionAroundVehicle, size / 2);
  const orientation = orientationAngle ? getPointOnCircle(orientationAngle, size / 2) : null;
  const x = orientation ? pos.x - orientation.x : 0;
  const y = orientation ? pos.y - orientation.y : 0;

  return {
    container: {
      ...styles['container'],
      ...responsive(styles['containerSmall']),
      width: size,
      height: size,
    },
    pov: {
      ...styles['pov'],
      transform: ` translate(${x}px, ${y}px) rotate(${
        orientationAngle ?? positionAroundVehicle
      }deg)`,
    },
  };
}
