import { useObjectMemo } from '@monkvision/common';
import { sights } from '@monkvision/sights';
import { PhotoCaptureAppConfig, Sight } from '@monkvision/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Parameters of the usePhotoCaptureTutorial hook.
 */
export interface PhotoCaptureSightTutorialParams
  extends Pick<PhotoCaptureAppConfig, 'sightTutorial'> {
  selectedSight: Sight;
}

export interface HandlePhotoCaptureSightTutorial {
  /**
   * Boolean indicating whether the sight tutorial should be displayed.
   */
  showSightTutorial: boolean;
  /**
   * Callback called when the user clicks on the "help" button in PhotoCapture.
   */
  toggleSightTutorial: () => void;
  // currentSightTutorialPictureURL: string;
  // sightTutorialGuideline: string;
}

function getSightsByLabel(label: string): string[] {
  return Object.values(sights).reduce<string[]>((acc, sight) => {
    if (sight.label === label) {
      acc.push(sight.id);
    }
    return acc;
  }, []);
}

function getOrientation(sightId: string, angleBySightId: Record<number, string[]>): number {
  const orientation = Object.entries(angleBySightId).find(([_, values]) =>
    values.includes(sightId),
  )?.[0];
  return orientation ? Number.parseInt(orientation, 10) : 0;
}

const angleBySightId: Record<number, string[]> = {
  0: getSightsByLabel(sights['jgc21-QIvfeg0X'].label),
  45: getSightsByLabel(sights['jgc21-KyUUVU2P'].label),
  90: getSightsByLabel(sights['jgc21-z15ZdJL6'].label),
  180: getSightsByLabel(sights['jgc21-m2dDoMup'].label),
  270: getSightsByLabel(sights['jgc21-3JJvM7_B'].label),
};

/**
 * Custom hook used to manage the state of photo capture sight tutorial.
 */
export function usePhotoCaptureSightTutorial({
  selectedSight,
  sightTutorial,
}: PhotoCaptureSightTutorialParams): HandlePhotoCaptureSightTutorial {
  const [showSightTutorial, setShowSightTutorial] = useState<boolean>(false);

  const toggleSightTutorial = () => {
    setShowSightTutorial(!showSightTutorial);
  };

  // const currentTutorialPictureURL = useMemo(() => {
  //   const sightTutorialFound = sightTutorial?.find((value) =>
  //     Object.keys(value.imageReferenceBySightId).includes(selectedSight.id),
  //   );
  //   let tutorialPictureURL = '';
  //   if (sightTutorialFound) {
  //     tutorialPictureURL = sightTutorialFound.imageReferenceBySightId[selectedSight.id];
  //   } else if (selectedSight.referencePicture) {
  //     tutorialPictureURL = selectedSight.referencePicture;
  //   } else {
  //     tutorialPictureURL = selectedSight.overlay;
  //   }
  //   // const tutorialGuideline = sightTutorialFound?.[getLanguage(i18n.language)];
  //
  //   // pre cache image
  //   const tutorialImage = new Image();
  //   tutorialImage.src = tutorialPictureURL;
  //
  //   return tutorialPictureURL;
  // }, [selectedSight]);

  useEffect(() => {
    const sightTutorialFound = sightTutorial?.find((value) =>
      Object.keys(value.imageReferenceBySightId).includes(selectedSight.id),
    );

    const tutorialImage = new Image();
    if (sightTutorialFound) {
      tutorialImage.src = sightTutorialFound.imageReferenceBySightId[selectedSight.id];
    } else if (selectedSight.tutorial?.referencePicture) {
      tutorialImage.src = selectedSight.tutorial?.referencePicture;
    } else {
      tutorialImage.src =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wIAAgMBAAJo+wAAAABJRU5ErkJggg==';
    }
    console.log(tutorialImage);
  }, [selectedSight]);

  // pre cache image
  // useEffect(() => {
  //   const sightTutorialFound = sightTutorial?.find((value) =>
  //     Object.keys(value.imageReferenceBySightId).includes(selectedSight.id),
  //   );
  //
  //   const tutorialImage = new Image();
  //   if (sightTutorialFound) {
  //     tutorialImage.src = sightTutorialFound.imageReferenceBySightId[selectedSight.id];
  //   } else if (selectedSight.tutorial?.referencePicture) {
  //     tutorialImage.src = selectedSight.tutorial?.referencePicture;
  //   } else {
  //     tutorialImage.src = 'data:,';
  //   }
  // }, [selectedSight]);

  return useObjectMemo({ showSightTutorial, toggleSightTutorial });
}
