import { DynamicSVG } from '../DynamicSVG';
import { useIconAroundVehicleStyle } from './hooks';
import { styles } from './IconAroundVehicle.styles';
import { IconAroundVehicleProps } from './IconAroundVehicle.types';

// const CAR_SVG =
//   '<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2395_6275)"> <circle cx="70" cy="70" r="70" fill="#1C1C1E" fill-opacity="0.75" stroke="#F2F2F7" stroke-width="4" stroke-linecap="round" stroke-dasharray="4 20"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M89.3425 100.861C89.3425 103.499 88.092 105.982 85.9718 107.551C76.1612 114.816 62.7552 114.816 52.9446 107.551C50.8243 105.982 49.5738 103.499 49.5738 100.861V99.5297H49.5733C48.6582 99.5297 47.9163 96.2336 47.9163 92.1676C47.9163 88.1016 48.6582 84.8055 49.5733 84.8055H49.5738L49.5738 55.3617H49.5733C48.6582 55.3617 47.9163 52.0656 47.9163 47.9996C47.9163 43.9336 48.6582 40.6375 49.5733 40.6375H49.5738V37.2288C49.5738 33.7463 51.7417 30.632 55.0075 29.4228C64.332 25.9705 74.5843 25.9705 83.9088 29.4228C87.1746 30.632 89.3425 33.7463 89.3425 37.2288V40.6375H89.343C90.2581 40.6375 91 43.9336 91 47.9996C91 52.0656 90.2581 55.3617 89.343 55.3617H89.3425V68.2451V84.8055H89.343C90.2581 84.8055 91 88.1016 91 92.1676C91 96.2336 90.2581 99.5297 89.343 99.5297H89.3425V100.861ZM84.1215 82.1529C85.0948 83.8827 84.2003 86.0679 82.2934 86.6186L79.7031 87.3666C73.0093 89.2998 65.9051 89.2998 59.2112 87.3666L56.621 86.6186C54.7141 86.0679 53.8195 83.8827 54.7929 82.1529L59.515 73.7609L60.4944 73.9422C66.4192 75.039 72.4951 75.039 78.4199 73.9422L79.3993 73.7609L84.1215 82.1529ZM80.8766 41.1861C83.1804 41.6687 84.1116 44.4538 82.5613 46.225L80.5187 48.5586C79.8221 49.3545 78.7655 49.7369 77.7208 49.5711L77.1735 49.4843C72.0613 48.6731 66.8531 48.6731 61.7408 49.4843L61.1935 49.5711C60.1489 49.7369 59.0923 49.3545 58.3956 48.5586L56.353 46.225C54.8027 44.4538 55.7339 41.6687 58.0378 41.1861L61.8867 40.38C66.8795 39.3342 72.0348 39.3342 77.0277 40.38L80.8766 41.1861Z" fill="#F2F2F7"/> </g> <defs> <clipPath id="clip0_2395_6275"> <rect width="140" height="140" fill="white"/> </clipPath> </defs> </svg> ';
const CAR_SVG =
  '<svg width="44" height="87" viewBox="0 0 44 87" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M42.3425 74.8612C42.3425 77.4994 41.092 79.9815 38.9718 81.5515C29.1612 88.8162 15.7552 88.8162 5.94456 81.5515C3.82435 79.9815 2.57382 77.4994 2.57382 74.8612V73.5297H2.57332C1.65816 73.5297 0.916286 70.2336 0.916286 66.1676C0.916286 62.1016 1.65816 58.8055 2.57332 58.8055H2.57382L2.57382 29.3617H2.57332C1.65816 29.3617 0.916286 26.0656 0.916286 21.9996C0.916286 17.9336 1.65816 14.6375 2.57332 14.6375H2.57382V11.2288C2.57382 7.74635 4.74172 4.63197 8.00753 3.42282C17.332 -0.0295181 27.5843 -0.0295181 36.9088 3.42282C40.1746 4.63197 42.3425 7.74635 42.3425 11.2288V14.6375H42.343C43.2581 14.6375 44 17.9336 44 21.9996C44 26.0656 43.2581 29.3617 42.343 29.3617H42.3425V42.2451L42.3425 58.8055H42.343C43.2581 58.8055 44 62.1016 44 66.1676C44 70.2336 43.2581 73.5297 42.343 73.5297H42.3425V74.8612ZM37.1215 56.1529C38.0948 57.8827 37.2003 60.0679 35.2934 60.6186L32.7031 61.3666C26.0093 63.2998 18.9051 63.2998 12.2112 61.3666L9.62098 60.6186C7.71409 60.0679 6.81953 57.8827 7.79286 56.1529L12.515 47.7609L13.4944 47.9422C19.4192 49.039 25.4951 49.039 31.4199 47.9422L32.3993 47.7609L37.1215 56.1529ZM33.8766 15.1861C36.1804 15.6687 37.1116 18.4538 35.5613 20.225L33.5187 22.5586C32.8221 23.3545 31.7655 23.7369 30.7208 23.5711L30.1735 23.4843C25.0613 22.6731 19.8531 22.6731 14.7408 23.4843L14.1935 23.5711C13.1489 23.7369 12.0923 23.3545 11.3956 22.5586L9.35304 20.225C7.80272 18.4538 8.73393 15.6687 11.0378 15.1861L14.8867 14.38C19.8795 13.3342 25.0348 13.3342 30.0277 14.38L33.8766 15.1861Z" fill="#F2F2F7"/> </svg>';
const POV_SVG =
  ' <svg width="164" height="164" viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2395_4045)"> <path d="M14.2565 68.2147C10.3089 62.3858 12.045 54.4394 18.0639 50.7879L37.3072 39.1137C64.7728 22.4512 99.2272 22.4512 126.693 39.1137L145.936 50.7879C151.955 54.4394 153.691 62.3858 149.743 68.2147L93 152H71L14.2565 68.2147Z" fill="url(#paint0_linear_2395_4045)"/> <circle cx="82" cy="152" r="11" fill="#FFC000" stroke="white" stroke-width="2"/> </g> <defs> <linearGradient id="paint0_linear_2395_4045" x1="85.0712" y1="70.8337" x2="85.0712" y2="151.615" gradientUnits="userSpaceOnUse"> <stop stop-color="#FFC000" stop-opacity="0"/> <stop offset="1" stop-color="#FFC000"/> </linearGradient> <clipPath id="clip0_2395_4045"> <rect width="164" height="164" fill="white"/> </clipPath> </defs> </svg> ';
const CIRCLE_SVG =
  '<svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="72" cy="72" r="70" fill="#1C1C1E" fill-opacity="0.75" stroke="#F2F2F7" stroke-width="4" stroke-linecap="round" stroke-dasharray="4 20"/> </svg>';

/**
 * Component used to display an icon representing a vehicle and a point of view (POV) indicator.
 */
export function IconAroundVehicle({
  size = 50,
  positionAroundVehicle = 0,
  orientationAngle,
  disabledCircle = false,
  ...passThroughProps
}: IconAroundVehicleProps) {
  const style = useIconAroundVehicleStyle({
    size,
    positionAroundVehicle,
    orientationAngle,
  });

  return (
    <div style={style.container} {...passThroughProps}>
      {!disabledCircle && (
        <DynamicSVG svg={CIRCLE_SVG} width={size} height={size} style={styles['car']} />
      )}
      <DynamicSVG svg={CAR_SVG} width={size * 0.6} height={size * 0.6} style={styles['car']} />
      <DynamicSVG svg={POV_SVG} width={size * 1.1} height={size * 1.1} style={style.pov} />
    </div>
  );
}
