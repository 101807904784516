import { changeAlpha, useMonkTheme } from '@monkvision/common';
import { styles } from './PhotoCaptureHUDSightTutorial.styles';

export function usePhotoCaptureHUDSightTutorialStyles() {
  const { palette } = useMonkTheme();

  return {
    guideline: {
      ...styles['guideline'],
      backgroundColor: `${changeAlpha(palette.background.base, 0.5)}`,
    },
  };
}
