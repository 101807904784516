import { CameraHeight } from '@monkvision/types';
import { DynamicSVG } from '../DynamicSVG';
import { IconVerticalPositionProps } from './IconVerticalPosition.types';

const HIGH_SVG =
  ' <svg width="116" height="95" viewBox="0 0 116 95" fill="none" xmlns="http://www.w3.org/2000/svg"> <mask id="path-1-inside-1_4018_34387" fill="white"> <path d="M50 2.8374H116V37.8374H50V2.8374Z"/> </mask> <path d="M50 2.8374H116V37.8374H50V2.8374Z" fill="#FFC000" fill-opacity="0.4"/> <path d="M50 7.8374H116V-2.1626H50V7.8374Z" fill="#FFC000" mask="url(#path-1-inside-1_4018_34387)"/> <ellipse cx="19.5" cy="7.42188" rx="7.5" ry="7.42188" fill="white"/> <rect x="8" y="17.0015" width="22" height="38" rx="2" fill="white"/> <path d="M30.7497 17.0015C30.8841 17.0015 30.9895 17.1169 30.9773 17.2507C30.424 23.3394 25.319 28.0015 19.2052 28.0015L0.622287 28.0015C0.278736 28.0015 0.000232228 27.723 0.000232243 27.3794L0.000232307 25.9204C0.000232522 20.9946 3.99337 17.0015 8.91915 17.0015L30.7497 17.0015Z" fill="white"/> <rect y="22.0015" width="6" height="33" rx="3" fill="white"/> <rect x="20" y="41.0015" width="10" height="54" rx="5" fill="white"/> <rect x="8" y="41.0015" width="10" height="54" rx="5" fill="white"/> <rect x="27.4082" y="28.5142" width="6" height="32.2859" rx="3" transform="rotate(-135 27.4082 28.5142)" fill="white"/> </svg> ';
const MID_SVG =
  '<svg width="116" height="95" viewBox="0 0 116 95" fill="none" xmlns="http://www.w3.org/2000/svg"> <mask id="path-1-inside-1_4018_34386" fill="white"> <path d="M50 17H116V52H50V17Z"/> </mask> <path d="M50 17H116V52H50V17Z" fill="#FFC000" fill-opacity="0.4"/> <path d="M50 22H116V12H50V22Z" fill="#FFC000" mask="url(#path-1-inside-1_4018_34386)"/> <ellipse cx="19.6697" cy="7.5" rx="7.56522" ry="7.5" fill="white"/> <rect x="8" y="17" width="22" height="38" rx="2" fill="white"/> <path d="M30.5358 17C31.2982 17 31.8745 17.6905 31.7381 18.4406C30.7318 23.9762 25.9106 28 20.2843 28L0.679677 28C0.304491 28 0.000342855 27.6959 0.000342872 27.3207L0.000342933 25.9189C0.000343148 20.9931 3.99348 17 8.91926 17L30.5358 17Z" fill="white"/> <rect y="22" width="6" height="33" rx="3" fill="white"/> <rect x="20" y="41" width="10" height="54" rx="5" fill="white"/> <rect x="8" y="41" width="10" height="54" rx="5" fill="white"/> <path d="M32.3787 30.1321C33.2493 32.0113 35.4785 32.8289 37.3577 31.9583C38.4852 31.436 38.9758 30.0985 38.4534 28.971L33.7054 18.7223C33.0089 17.2189 31.2256 16.5648 29.7223 17.2613C28.2189 17.9577 27.5648 19.7411 28.2613 21.2444L32.3787 30.1321Z" fill="white"/> <rect x="36.2324" y="33.2891" width="6" height="19.8993" rx="3" transform="rotate(-134.856 36.2324 33.2891)" fill="white"/> </svg> ';
const LOW_SVG =
  '<svg width="116" height="95" viewBox="0 0 116 95" fill="none" xmlns="http://www.w3.org/2000/svg"> <mask id="path-1-inside-1_4018_34385" fill="white"> <path d="M50 47H116V82H50V47Z"/> </mask> <path d="M50 47H116V82H50V47Z" fill="#FFC000" fill-opacity="0.4"/> <path d="M50 52H116V42H50V52Z" fill="#FFC000" mask="url(#path-1-inside-1_4018_34385)"/> <circle cx="19.5" cy="7.5" r="7.5" fill="white"/> <rect x="8" y="17" width="22" height="38" rx="2" fill="white"/> <path d="M30.2181 17C30.638 17 30.9672 17.3605 30.9292 17.7787C30.4031 23.5675 25.5495 28 19.7368 28L0.622287 28C0.278736 28 0.000232228 27.7215 0.000232243 27.378L0.000232307 25.9189C0.000232522 20.9931 3.99337 17 8.91915 17L30.2181 17Z" fill="white"/> <rect y="22" width="6" height="33" rx="3" fill="white"/> <rect x="20" y="41" width="10" height="54" rx="5" fill="white"/> <rect x="8" y="41" width="10" height="54" rx="5" fill="white"/> <path d="M42.9941 49.1594C43.8325 50.5723 45.6324 51.0786 47.0844 50.3098C48.583 49.5165 49.1586 47.6612 48.3723 46.1589L34.4014 19.4681C33.3215 17.405 30.8557 16.4937 28.6937 17.3585C26.7533 18.1346 25.9637 20.4596 27.0302 22.2569L42.9941 49.1594Z" fill="white"/> </svg> ';

function getSvg(position: CameraHeight): string {
  switch (position) {
    case CameraHeight.HIGH:
      return HIGH_SVG;
    case CameraHeight.MID:
      return MID_SVG;
    case CameraHeight.LOW:
      return LOW_SVG;
    default:
      return MID_SVG;
  }
}

/**
 * Component used to display an icon representing a vehicle and a point of view (POV) indicator.
 */
export function IconVerticalPosition({
  size = 50,
  position = CameraHeight.MID,
}: IconVerticalPositionProps) {
  return <DynamicSVG svg={getSvg(position)} width={size} height={size} />;
}
