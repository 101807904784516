import { Styles } from '@monkvision/types';

export const styles: Styles = {
  container: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 10,
    transition: 'opacity 0.5s ease-out',
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
    backdropFilter: 'blur(10px)',
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
    width: '15%',
  },
  sightIcon: { width: '85px' },
  tutorialContainer: { position: 'relative', width: '70%', height: '100%' },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
  },
  image: {
    width: '75%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: '9px',
    border: 'solid #FFC000',
  },
  guideline: {
    position: 'absolute',
    borderRadius: '9px',
    padding: '10px',
    bottom: '10px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  icon: { height: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  closeButton: { position: 'absolute', right: 0 },
  closeButtonFiller: { visibility: 'hidden' },
};
